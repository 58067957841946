@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300italic,600italic,300,600');

/*
	Dimension by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

// Breakpoints.

	@include breakpoints((
		xlarge:   ( 1281px,  1680px ),
		large:    ( 981px,   1280px ),
		medium:   ( 737px,   980px  ),
		small:    ( 481px,   736px  ),
		xsmall:   ( 361px,   480px  ),
		xxsmall:  ( null,    360px  )
	));

// Base.

	@import 'base/reset';
	@import 'base/page';
	@import 'base/typography';

// Component.

	@import 'components/form';
	@import 'components/box';
	@import 'components/icon';
	@import 'components/image';
	@import 'components/list';
	@import 'components/actions';
	@import 'components/icons';
	@import 'components/table';
	@import 'components/button';

// Layout.
    // Needs to be here to use liquid, too much of a refactor to use deeper
    $background_image: url("../../assets/images/bg.jpg");

	@import 'layout/_bg';
	@import 'layout/wrapper';
	@import 'layout/header';
	@import 'layout/main';
	@import 'layout/footer';
    article {

	
	  h2.major {
		width: auto;
	
	  }
	  p {
		  font-size: 1rem;
		  font-weight:900;
	  }
   }
nav.page-nav {
	width: 100%;
	top:0;
	left:0;
	padding: 5px;
    background-color: rgba(27, 31, 34, 0.85);
}

.adsbygoogle {
	z-index: 999;
    position: relative;
}